import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import provideContext from "lib/provideContext";
import { useMiddleware, getAuthToken, default as railsFetch } from "lib/railsFetch";
import { getMiddleware } from "lib/authMiddleware";
import wrapProvider from "lib/wrapProvider";
import { store } from "reducers";
import { Button, Checkbox, Icon } from "@devforce/tds-react";
import { getLocale, localizeDate } from "@devforce/trailhead-client-locale";
import * as env from "@devforce/trailhead-client-env";
import { buildScopeTranslate } from "lib/I18n";
import {
  initSpecialityOrgManager,
  connectOrg,
  provisionOrg,
  initRequestOrg,
  requestOrg,
  setEmail,
  showError,
} from "actions/specialityOrgActions";
import Poller from "lib/Poller";
import { specialityOrgStatus } from "reducers/specialityOrg";
import LoginModalBtn from "components/auth/buttons/LoginModalBtn";
import IconContext from "components/utils/IconContext";

const POLLING_INTERVAL = 5000;
const POLLING_MAX_ATTEMPTS = 1200;
const tManage = buildScopeTranslate("speciality_org.manage");
const missingEmailHelpLink = "https://trailhead.salesforce.com/help?article=Trailhead-Account-Email";
const genericHelpLink = "https://trailhead.salesforce.com/help?article=Connect-a-Salesforce-or-Social-Login-to-Your-Trailhead-Account";

const timeFormatter = new Intl.DateTimeFormat(getLocale(), {
  hour: "numeric",
  minute: "numeric",
  timeZoneName: "short",
  hour12: true,
});

function SpecialityOrgManager({
  authenticated,
  authorizedAccount,
  connectOrg,
  email,
  initRequestOrg,
  orgData,
  orgRequestPending,
  previewMode,
  productLabel,
  provisionOrg,
  requestOrg,
  setEmail,
  showError,
  status,
  statusUrl,
  tbidEmail,
  testId,
  unitUid,
}) {
  const hasOrgProvisioned = !!orgData;
  const isInitialState = [specialityOrgStatus.INITIAL, specialityOrgStatus.ERROR].includes(status);
  const [poller, setPoller] = useState(null);
  const [emailConfirmed, setEmailConfirmed] = useState(false);
  // Set the authorization header
  const middleware = getMiddleware("provision_requests");
  // Used to hide the Launch button when the org is connected and the user is viewing the unit in preview mode,
  // since the org picker is rendered below this component and already has a Launch button.
  const orgConnectedInPreviewMode = previewMode && status === specialityOrgStatus.CONNECTED;
  useMiddleware(middleware);

  const createPoller = (statusUrl) => {
    return new Poller({
      createRequest: () => railsFetch({ url: statusUrl, method: "get" }),
      interval: POLLING_INTERVAL,
      isComplete: (response) => {
        return response.data.state !== "pending";
      },
      maxRetries: POLLING_MAX_ATTEMPTS,
      onComplete: (response) => {
        if (response.data.state === "created") {
          provisionOrg({
            orgId: response.data.organization_id,
            expiresAt: response.data.expires_at,
            productName: authorizedAccount.name,
            username: response.data.username,
            orgRequestId: response.data.id,
          });
        }
        if (response.data.state === "failed") {
          const statusUrl = `/api/v1/orgs/provision_requests/${response.data.id}`;
          showError({ statusUrl });
        }
        return;
      },
      onError: (error) => {
        showError(error);
      },
      onTimeout: () => {
        showError({});
      },
    });
  };

  // Set the initial state if the user has an existing org request
  useEffect(() => {
    if (tbidEmail) {
      setEmail({ email: tbidEmail });
    }
    if (authorizedAccount) {
      if (authorizedAccount.org_request) {
        const orgRequest = authorizedAccount.org_request;
        if (orgRequest.state === "pending") {
          requestOrg({
            statusUrl: orgRequest.status_url,
            email: orgRequest.email,
          });
          const pollerInstance = createPoller(orgRequest.status_url);
          setPoller(pollerInstance);
          pollerInstance.start();
        } else if (orgRequest.state === "created") {
          provisionOrg({
            orgId: orgRequest.organization_id,
            expiresAt: authorizedAccount.expires_at,
            expiresSoon: authorizedAccount.expires_soon,
            launchPath: authorizedAccount.launch_de_path,
            productName: authorizedAccount.name,
            username: orgRequest.username,
            orgRequestId: orgRequest.id,
          });
        } else if (orgRequest.state === "failed") {
          showError({ statusUrl: orgRequest.status_url });
        }
      }
      if (authorizedAccount.active_org) {
        connectOrg({
          productName: authorizedAccount.name,
          expiresAt: authorizedAccount.expires_at,
          expiresSoon: authorizedAccount.expires_soon,
          launchPath: authorizedAccount.launch_de_path,
        });
      }
    }
    return () => {
      poller && poller.stop();
    };
  }, []);

  const createOrgRequest = () => {
    // Make a POST request to api/v1/orgs/provision_requests with the unit uid
    railsFetch({
      url: `/api/v1/orgs/provision_requests`,
      method: "post",
      data: {
        unit_uid: unitUid,
      }
    })
      .then((response) => {
        const statusUrl = `/api/v1/orgs/provision_requests/${response.data.id}`;
        if (response.data.state === "pending") {
          requestOrg({ statusUrl, email: response.data.email });
          const pollerInstance = createPoller(statusUrl);
          setPoller(pollerInstance);
          pollerInstance.start();
        } else if (response.data.state === "created") {
          provisionOrg({
            orgId: response.data.organization_id,
            expiresAt: response.data.expires_at,
            productName: authorizedAccount?.name,
            username: response.data.username,
            orgRequestId: response.data.id,
          });
        } else {
          showError({ statusUrl });
        }
      })
      .catch((error) => {
        window.onerror({
          message: new Error(JSON.stringify(error)),
          filename: 'SpecialityOrgManager.jsx'
        });
        // If the user encounters a 409 Conflict (likely due to already having requested a playground in a separate tab), refresh the page.
        if (error.railsFetchStatusCode === 409) {
          return window.location.reload();
        }
        showError(error);
      });
  };

  const handleSubmitButtonAction = async (e) => {
    e.preventDefault();
    if (hasOrgProvisioned) {
      if (status === specialityOrgStatus.CONNECTED && orgData.launchPath) {
        return window.open(orgData.launchPath, "_blank");
      } else {
        window.location.href = `/sessions/de/new?org_request_id=${orgData.orgRequestId}`;
        return;
      }
    }
    // Set loading state while waiting for network request to resolve
    initRequestOrg();
    // If an org request is in an error state, delete the previous org request first.
    if (status === specialityOrgStatus.ERROR && statusUrl) {
      try {
        poller && poller.stop();
        await railsFetch({
          url: statusUrl,
          method: "delete",
        });
      } catch (error) {
        showError(error);
      }
    }
    createOrgRequest();
  };

  const title = hasOrgProvisioned
    ? tManage(status === specialityOrgStatus.CONNECTED ? "title.connected" : "title.provisioned", {
        type: productLabel || ""
      })
    : tManage(orgRequestPending ? "title.requesting" : "title.intro", { type: productLabel || "" });
  const buttonLabel = hasOrgProvisioned
    ? status === specialityOrgStatus.CONNECTED
      ? tManage("button.launch")
      : tManage("button.connect_org")
    : tManage("button.request_org");

  const renderExpirationDate = () => {
    if (status === specialityOrgStatus.CONNECTED) {
      const { expiresAt, expiresSoon } = orgData;
      const expiresAtDate = new Date(expiresAt);
      const localizedDate = localizeDate(expiresAt);
      // Remove the zero-width space character, since jest tests in sfci only use regular spaces,
      // causing the snapshot tests to fail.
      const localizedTime = timeFormatter.format(expiresAtDate).replace(/\u202F/g, " ");
      return (
        <div className={`slds-badge ${expiresSoon ? "th-badge--error" : "th-badge--warning"}`}>
          <Icon
            assistiveText={{
              label: ""
            }}
            variant="neutral"
            size="x-small"
            name={expiresSoon ? "priority" : "clock"}
          />
          {tManage(expiresSoon ? "expiration.expires_soon" : "expiration.expires", {
            date: localizedDate,
            time: localizedTime
          })}
        </div>
      );
    }
  };

  const renderDescription = () => {
    if (hasOrgProvisioned) {
      if (status === specialityOrgStatus.CONNECTED) {
        return <p className="th-text--small">{tManage("description.connected", { type: productLabel })}</p>;
      } else {
        return (
          <>
            <p className="th-text--small">{tManage("description.provisioned")}</p>
            <ul className="slds-list_dotted th-text--small">
              <li>{tManage("description.provisioned_step_1")}</li>
              <li
                dangerouslySetInnerHTML={{
                  __html: tManage("description.provisioned_step_2", { button_name: tManage("button.connect_org") })
                }}
              ></li>
              <li
                dangerouslySetInnerHTML={{
                  __html: tManage("description.provisioned_step_3", { username: orgData.username || '' })
                }}></li>
              <li>{tManage("description.provisioned_step_4")}</li>
              <li>{tManage("description.provisioned_password_reset_note")}</li>
            </ul>
          </>
        );
      }
    }
    return <p className="th-text--small">{tManage("description.intro", { type: productLabel })}</p>;
  };

  const renderEmailConfirmation = () => {
    if (authenticated && isInitialState && !orgRequestPending) {
      return (
        <>
          <div className="th-speciality-org-manager__email-confirmation">
            <Checkbox
              assistiveText={{
                label: tManage("checkbox.confirm_email", { email })
              }}
              labels={{
                label: tManage("checkbox.confirm_email", { email })
              }}
              id={testId}
              name="email-confirmation"
              checked={emailConfirmed}
              required
              onChange={(e) => {
                setEmailConfirmed(e.target.checked);
              }}
            />
          </div>
        </>
      );
    }
  };

  const renderStatusPartial = ({ type, icon, text }) => {
    return (
      <div className="th-speciality-org-manager__status">
        <div className={`th-speciality-org-manager__status--text th-speciality-org-manager__status--${type}`}>
          <Icon
            assistiveText={{
              label: icon
            }}
            variant="neutral"
            size="x-small"
            name={icon}
          />
          <div
            dangerouslySetInnerHTML={{
              __html: text
            }}
          />
        </div>
      </div>
    );
  };

  const renderStatus = () => {
    if (authenticated && status === specialityOrgStatus.INITIAL) {
      const profileurl = env.get("TBID_PROFILE_URL");
      return renderStatusPartial({
        type: "neutral",
        icon: "info_alt",
        text: tManage("status.confirm_email", { link: `${profileurl}settings` }),
      });
    } else if (status === specialityOrgStatus.ERROR) {
      return renderStatusPartial({
        type: "error",
        icon: "error",
        text: tManage("status.generic_error", { type: productLabel, link: genericHelpLink }),
      });
    } else if (status === specialityOrgStatus.REQUEST_IN_PROGRESS) {
      return renderStatusPartial({ type: "neutral", icon: "email", text: tManage("status.requesting", { email }) });
    } else if (status === specialityOrgStatus.PROVISIONED) {
      return renderStatusPartial({
        type: "success",
        icon: "notification",
        text: tManage("status.provisioned", { email, link: missingEmailHelpLink }),
      });
    }
  };

  return (
    <div className="th-speciality-org-manager__container">
      <IconContext>
        <div className="th-speciality-org-manager__content">
          <div className="th-speciality-org-manager__left-container">
            <div className="th-speciality-org-manager__heading">
              <h3 className="th-text--medium th-text--bold tds-color_midnight">{title}</h3>
              {renderExpirationDate()}
            </div>
            {renderDescription()}
            {renderEmailConfirmation()}
          </div>
          {!orgConnectedInPreviewMode && <div className="th-speciality-org-manager__right-container">
            {authenticated ? (
              <Button
                spinner={orgRequestPending}
                disabled={orgRequestPending || (isInitialState && !emailConfirmed)}
                label={buttonLabel}
                variant={status === specialityOrgStatus.CONNECTED ? "brand" : "neutral"}
                onClick={handleSubmitButtonAction}
              />
            ) : (
              <LoginModalBtn className={"slds-button slds-button_neutral"} content={tManage("button.login")} />
            )}
            </div>
          }
        </div>
        {renderStatus()}
      </IconContext>
    </div>
  );
}

SpecialityOrgManager.propTypes = {
  authenticated: PropTypes.bool.isRequired,
  authorizedAccount: PropTypes.object,
  email: PropTypes.string,
  orgData: PropTypes.object,
  orgRequestPending: PropTypes.bool,
  previewMode: PropTypes.bool,
  productLabel: PropTypes.string,
  status: PropTypes.string,
  statusUrl: PropTypes.string,
  tbidEmail: PropTypes.string,
  title: PropTypes.string,
  description: PropTypes.string,
  unitUid: PropTypes.string.isRequired,
};

const mapDispatchToProps = (dispatch) => ({
  initRequestOrg: () => dispatch(initRequestOrg()),
  setEmail: (email) => dispatch(setEmail(email)),
  requestOrg: (unitUid) => dispatch(requestOrg(unitUid)),
  provisionOrg: (orgData) => dispatch(provisionOrg(orgData)),
  connectOrg: (orgData) => dispatch(connectOrg(orgData)),
  showError: (error) => dispatch(showError(error)),
});

export default provideContext(
  wrapProvider({
    store,
    initAction: initSpecialityOrgManager,
    mapStateToProps: (state) => ({
      email: state.specialityOrg.email,
      orgData: state.specialityOrg.orgData,
      orgRequestPending: state.specialityOrg.orgRequestPending,
      status: state.specialityOrg.status,
      statusUrl: state.specialityOrg.statusUrl,
    }),
    mapDispatchToProps,
  })(SpecialityOrgManager)
);
