import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import flatten from 'lodash/flatten';
import compact from 'lodash/compact';

import {
  Card,
  CardEmpty,
  CardFilter,
  DataTableHighlightCell,
} from '@salesforce/design-system-react';

import {
  Alert,
  Button,
  DataTable,
  DataTableCell,
  DataTableColumn,
  Icon
} from '@devforce/tds-react';

import ReleaseHealthPoller from 'components/trailmaker/ReleaseHealthPoller';

import IconContext from 'components/utils/IconContext';
import { buildScopeTranslate } from 'lib/I18n';

const HealthCheckProgress = {
  Loading: 1,
  Error: 2,
  Complete: 3,
};

const IssueCountTableCell =
  ({
    children,
    fgColor,
    bgColor,
    ...props
  }) => ( // eslint-disable-line react/prop-types
    <DataTableCell {...props} >
      {children ? (<div
        style={{
          backgroundColor: bgColor,
          color: fgColor,
          borderRadius: '4px',
          width: '1.6em',
          height: '1.6em',
        }}
        className="slds-align--absolute-center">
        {children}
      </div>) : (<div />)}
    </DataTableCell>
  );
IssueCountTableCell.displayName = DataTableCell.displayName;

const SimpleTableCell = ({ children, ...props }) => ( // eslint-disable-line react/prop-types
  <DataTableCell {...props} >
    <div className="th-text slds-p-horizontal_x-small">
      {children}
    </div>
  </DataTableCell>
);
SimpleTableCell.displayName = DataTableCell.displayName;

const ItemTypeTableCell = ({ children, ...props }) => ( // eslint-disable-line react/prop-types
  <DataTableCell {...props} >
    <div
      className={classNames(
        'th-text',
        'slds-p-horizontal_x-small',
        {
          'th-color--destructive': children.toLowerCase() === 'error',
          'th-color--sunshine': children.toLowerCase() === 'warning',
        }
      )}
    >
      {children}
    </div>
  </DataTableCell>
);
ItemTypeTableCell.displayName = DataTableCell.displayName;

const ExpandableTableCell = ({ children, ...props }) => { // eslint-disable-line react/prop-types
  const expanded = props.expanded(children); // eslint-disable-line react/prop-types
  return (
    <DataTableCell {...props} fixedLayout={false}>
      {children && <Button
        iconCategory="utility"
        iconName={expanded ? 'chevrondown' : 'chevronright'}
        iconSize="x-small"
        iconVariant="bare"
        className="slds-button--icon-bare"
        onClick={() => props.onExpand(children)} // eslint-disable-line react/prop-types
        variant="icon"
      />}
    </DataTableCell>
  );
};
ExpandableTableCell.displayName = DataTableCell.displayName;

export default class ReleaseIssues extends React.Component {
  static propTypes = {
    items: PropTypes.arrayOf(PropTypes.object),
    api_name: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    state: PropTypes.string.isRequired,
    releaseHealthCheckJobId: PropTypes.string,
  };

  state = {
    filter: null,
    items: this.props.items.map((item, index) => {
      const issueCount =
        item.packcheck ? item.packcheck.errors.length + item.packcheck.warnings.length : 0;
      if (issueCount === 0) return null;
      return ({
        ...item,
        id: `${item.api_name}-${index}`,
        errorCount: item.packcheck.errors.length,
        warningCount: item.packcheck.warnings.length,
        expands: (issueCount > 0 ? item.api_name : null)
      });
    }).filter((el) => el != null),
    healthCheckProgress: HealthCheckProgress.Loading,
    healthCheckResults: {
      errors: [],
      warnings: [],
    },
    releaseIssues: [],
    expandedItems: [],
  };

  handleHealthCheckError = () => {
    this.setState({healthCheckProgress: HealthCheckProgress.Error});
  };

  handleHealthCheckCompletion = (response) => this.setState({
    healthCheckProgress: HealthCheckProgress.Complete,
    healthCheckResults: response.data.results,
    releaseIssues: flatten(compact([
      response.data.results.errors,
      response.data.results.warnings])).map((item, index) => ({
      id: `release-issue-${index}`,
      message: item,
    })),
  });

  handleFilterChange = (event) => {
    const filter =
      event.target.value !== '' ? RegExp(event.target.value, 'i') : null;

    this.setState({
      filter,
    });
  };

  handleExpansion = (apiName) => {
    if (!this.isExpanded(apiName)) {
      this.setState((prevState) => ({
        expandedItems: [...prevState.expandedItems, apiName]
      }));
    } else {
      // console.log('Would collapse', apiName);
      this.setState((prevState) => ({
        expandedItems: prevState.expandedItems.filter((item) => item !== apiName)
      }));
    }
    // Alter the items state key to insert errors and warnings for each api_name in expandedItems
  };

  isExpanded = (apiName) => (
    this.state.expandedItems.findIndex((item) => item === apiName) !== -1
  );

  render() {
    let items = [...this.state.items];

    const { releaseIssues, healthCheckProgress } = this.state;

    if (this.state.filter) {
      items = items.filter((item) => this.state.filter.test(item.api_name));
    }

    this.state.expandedItems.forEach((apiName) => {
      const expandedItemIndex = items.findIndex((item) => item.api_name === apiName);
      // When filtering, it may be that one of the previously expanded items are no longer in items
      if (expandedItemIndex !== -1) {
        const issues = items[expandedItemIndex].packcheck;
        const issueRows = [];
        if (issues) {
          issues.errors.forEach((error, index) => {
            issueRows.push({
              id: `${apiName}-error-${index}`,
              api_name: error,
              type: 'Error',
              issueCount: null,
              operation: null,
            });
          });
          issues.warnings.forEach((warning, index) => {
            issueRows.push({
              id: `${apiName}-warning-${index}`,
              api_name: warning,
              type: 'Warning',
              issueCount: null,
              operation: null,
            });
          });
        }
        items.splice(expandedItemIndex + 1, 0, ...issueRows);
      }
    });

    const isEmpty = items.length === 0;

    let packCheckErrorCount = 0;
    let packCheckWarningCount = 0;

    items.forEach((manifestItem) => {
      if (manifestItem.packcheck) {
        packCheckErrorCount += manifestItem.packcheck.errors.length;
        packCheckWarningCount += manifestItem.packcheck.warnings.length;
      }
    });

    const tIssues = buildScopeTranslate('views.trailmaker.release_issues');
    const tRelease = buildScopeTranslate('views.trailmaker.release');

    return (
      <IconContext>
        <div>
          <div className="slds-p-around--medium">
            <div className="slds-container_x-large slds-container_center slds-p-around_small slds-grid slds-grid_vertical-align-center">
              <Icon
                category="utility"
                name="warning"
                size="large"
                colorVariant="error"
              />
              <div className="slds-col slds-p-horizontal--medium">
                <div className="th-text th-text--small th-text--capitalize">
                  {tRelease(`details.status.${this.props.state}`)}
                </div>
                <div className="slds-text-heading_large">
                  {tIssues('title', { release_name: this.props.label })}
                </div>
              </div>
            </div>
          </div>
          <ReleaseHealthPoller
            jobId={this.props.releaseHealthCheckJobId}
            onComplete={this.handleHealthCheckCompletion}
            onError={this.handleHealthCheckError}
            onTimeout={this.handleHealthCheckError} />
          {healthCheckProgress === HealthCheckProgress.Loading && <Alert
            labels={{
              heading: tRelease('packcheck_checking_header'),
            }}
            icon={'spinner'}
            skipContainer
            style={{'backgroundColor': '#16325c'}}
            variant={'info'}
          />
          }
          {healthCheckProgress === HealthCheckProgress.Error && <Alert
            labels={{
              heading: tRelease('packcheck_checking_failed_header'),
              headingLink: tRelease('packcheck_checking_failed_link'),
            }}
            onClickHeadingLink={() => {
              window.location.reload();
            }}
            icon={'ban'}
            skipContainer
            variant={'error'}
          />
          }
          {releaseIssues.length > 0 &&
          <div className="slds-container_x-large slds-container_center slds-p-horizontal--large">
            <Card
              id="ReleaseIssues"
              heading={tIssues('release_issues', { count: releaseIssues.length })}
            >
              <DataTable
                items={releaseIssues}
                id={`${this.props.api_name}-release-issues`}
                className="th-table--line-wrap tds-text-size_4"
              >
                <DataTableColumn label={tIssues('release_issue_message')} property="message" />
              </DataTable>
            </Card>
          </div>
          }

          <div className="slds-container_x-large slds-container_center slds-p-horizontal--large">
            <Card
              id="ContentIssues"
              heading={tIssues('content_issues', { count: packCheckErrorCount + packCheckWarningCount })}
              filter={
                (!isEmpty || this.state.filter) && (
                  <CardFilter
                    onChange={this.handleFilterChange}
                    assistiveText={{
                      label: tIssues('filter_placeholder')
                    }}
                    placeholder={tIssues('filter_placeholder')}
                  />
                )
              }
              empty={isEmpty && <CardEmpty heading={tIssues('no_matches')} />}
              className="slds-p-around--medium"
            >
              <DataTable
                items={items}
                id={`${this.props.api_name}-content-issues`}
                className="th-table--line-wrap tds-text-size_4"
                fixedLayout
              >
                <DataTableColumn property="expands" width="1rem">
                  <ExpandableTableCell expanded={this.isExpanded} onExpand={this.handleExpansion} />
                </DataTableColumn>
                <DataTableColumn label={tIssues('type')} property="type" width="3rem">
                  <ItemTypeTableCell />
                </DataTableColumn>
                <DataTableColumn label={tIssues('api_name')} property="api_name" width="25rem">
                  <DataTableHighlightCell search={this.state.filter} className="slds-p-horizontal_x-small" />
                </DataTableColumn>
                <DataTableColumn label={tIssues('operation')} property="operation" width="4rem">
                  <SimpleTableCell />
                </DataTableColumn>
                <DataTableColumn label={tIssues('errors')} property="errorCount" width="3rem">
                  <IssueCountTableCell fgColor="#f6e4e4" bgColor="#c23934" />
                </DataTableColumn>
                <DataTableColumn label={tIssues('warnings')} property="warningCount" width="4rem">
                  <IssueCountTableCell fgColor="#251905" bgColor="#ffb600" />
                </DataTableColumn>
              </DataTable>
            </Card>
          </div>
        </div>
      </IconContext>
    );
  }
}
