import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Option from './Option';

export default class Question extends React.Component {
  static propTypes = {
    index: PropTypes.number.isRequired,
    question: PropTypes.object.isRequired,
    onSelectOption: PropTypes.func.isRequired,
    disabled: PropTypes.bool.isRequired,
    correct: PropTypes.bool,
    error: PropTypes.bool,
    initialSelection: PropTypes.number,
  }

  static defaultProps = {
    initialSelection: null
  }

  state = {
    selectedIndex: this.props.initialSelection
  }

  onSelect = (oIndex) => {
    this.setState({
      selectedIndex: oIndex
    });

    this.props.onSelectOption(this.props.index, oIndex);
  }

  isChecked = (idx) => (
    idx === this.state.selectedIndex
  )

  isError = (idx) => (
    this.isChecked(idx) && this.props.error
  )

  isCorrect = (idx) => (
    this.props.correct &&
    idx === this.state.selectedIndex
  )

  isDisabled = () => (
    this.props.disabled || this.props.correct
  )

  optionStatus = (idx) => {
    if (this.isCorrect(idx)) {
      return 'correct';
    } else if (this.isError(idx)) {
      return 'error';
    } else if (this.isChecked(idx)) {
      return 'checked';
    }
    return 'deselected';
  }

  renderOption = (option, idx) => (
    <Option
      oIndex={idx}
      key={`q_${this.props.index}-o_${idx}`}
      option={option}
      qIndex={this.props.index}
      onSelect={this.onSelect}
      disabled={this.isDisabled()}
      status={this.optionStatus(idx)}
    />
  )

  render() {
    const legendClassNames = classNames(
      'th-quiz__legend', {
        'th-quiz__legend--error': this.props.error
      });

    // describedby? labelledby?
    return (
      <fieldset
        aria-invalid={this.isError()}
        className="slds-form-element th-quiz__question"
      >
        <legend>
          <div className={legendClassNames}>
            <span className="th-quiz__question-icon">{this.props.index + 1}</span>
            <span className="th-quiz__question-text">{this.props.question.question}</span>
          </div>
        </legend>
        <div className="slds-form-element__control">
          <div className="slds-radio_button-group">
            {this.props.question.options.map(this.renderOption)}
          </div>
        </div>
      </fieldset>
    );
  }
}
