import React, { useEffect, useState, useRef } from "react";
import PropTypes from 'prop-types';
import compact from 'lodash/compact';
import isEmpty from 'lodash/isEmpty';
import trim from 'lodash/trim';
import uniqueId from 'lodash/uniqueId';
import { Settings } from '@salesforce/design-system-react';
import {
  Button, Input,
  Modal, Tooltip,
} from '@devforce/tds-react';

import railsFetch from '../../../../lib/railsFetch';
import CategoryTranslation from './CategoryTranslation';
import { buildScopeTranslate } from '../../../../lib/I18n';
import navigate from '../../../../lib/navigate';
import languageShape from '../lib/shapes/language';

Settings.setAppElement("#main-wrapper");

const tModal = buildScopeTranslate('views.trailmaker.settings.category.category_entry_modal');
const tCat = buildScopeTranslate('views.trailmaker.settings.category');
const LABEL_MAX_LENGTH = 80;
const CONCEPT_ID_LENGTH = 36;
const TAXONOMY_VIEWER_URL = 'https://taxonomy-viewer.sfdcdigital.com/#!/ses/C360/tree/item/';
const conceptIDErrorText = (errorKeys, vars) => {
  if (isEmpty(errorKeys)) return null;

  return errorKeys.map((k) => (
    tModal(`concept_id_errors.${k}`, vars)
  )).join(' ');
};

const CategoryEntryModal = ({
  categoryGroupAPIName,
  categoryGroupId,
  id,
  isOpen,
  languages,
  onCancel,
  onCategorySaved,
  onCategorySavedError,
  showingConceptId,
  editCategoryData,
  publicTrailmaker,
}) => {
  const API_CATEGORIES_ENDPOINT = '/api/v1/settings/categories';
  const DEFAULT_TRANSLATION = [
    {
      id: uniqueId('new-'),
      label: '',
      language: languages.find((l) => l.id === 'en'),
    }
  ];

  let initialTranslationData;
  initialTranslationData = DEFAULT_TRANSLATION;

  const [translationData, setTranslationData] = useState(initialTranslationData);
  const [newTranslation, setNewTranslation] = useState();
  const [errorTranslationData, setErrorTranslationData] = useState([]);
  const [errorTranslationDataOnSave, setErrorTranslationDataOnSave] = useState([]);
  const [conceptId, setConceptId] = useState('');
  const [errorConceptId, setErrorConceptId] = useState([]);
  const [isSaving, setIsSaving] = useState(false);
  const mounted = useRef(false);

  // Capture mounted state in order to cancel async actions
  useEffect(() => {
    mounted.current = true;
    return () => {
      mounted.current = false;
    };
  }, []);

  useEffect(() => {
    if (isOpen && editCategoryData) {
      const categoryId = editCategoryData.id;
      setConceptId(categoryId);
      updateConceptId(categoryId);
      const updatedTranslationData = [];
      const rawTranslationsData = editCategoryData.name[0].translations;

      for (const key in rawTranslationsData) {
        if (rawTranslationsData[key]) {
          const element = rawTranslationsData[key];
          updatedTranslationData.push({
            id: uniqueId('new-'),
            label: element,
            language: languages.find((l) => l.id === key),
          });
        }
      }
      setTranslationData(updatedTranslationData);
    }
  }, [isOpen]);

  // Moves focus to the first input field that has a form validation error.
  // The value of errorTranslationDataOnSave doesn't actually matter; it's just used to trigger
  // this useEffect to switch focus. We can't use errorTranslationData as the dependency since it
  // gets cleared whenever a user modifies an existing field, rather than only when the user
  // attempts to save.
  useEffect(() => {
    const errorInput = document.querySelector('.slds-has-error input');
    if (errorInput) {
      errorInput.focus();
    }
  }, [errorTranslationDataOnSave]);

  // Sets focus to the new translation input field each time the user clicks the add translation button
  // to improve accessibility
  useEffect(() => {
    if (newTranslation) {
      const newTranslationElem = document.querySelector(`#input-category-label${newTranslation.id}`);
      newTranslationElem.focus();
    }
  }, [newTranslation])

  let languageIDsInUse = [];
  if (translationData) {
    languageIDsInUse = compact(translationData.map((t) => (t.language ? t.language.id : null)));
  }
  const availableLanguages = languages.filter((l) => !languageIDsInUse.includes(l.id));

  const addTranslation = () => {
    const updatedTranslationData = Array.from(translationData);
    const newTranslationData = {
      id: uniqueId('new-'),
      label: '',
      language: availableLanguages.length === 1 ? availableLanguages[0] : null,
    };
    updatedTranslationData.push(newTranslationData);
    setNewTranslation(newTranslationData);
    setTranslationData(updatedTranslationData);
  };

  const removeTranslation = (id) => {
    setTranslationData(translationData.filter((t) => t.id !== id));
    // Set focus to the Add Translation button when a filter is deleted
    const addTranslationButton = document.querySelector('#add-translation-button');
    addTranslationButton.focus();

  };

  const updateField = (id, value, key) => {
    const updatedTranslationData = Array.from(translationData);
    const translation = updatedTranslationData.find((t) => t.id === id);
    translation[key] = value;
    setTranslationData(updatedTranslationData);
    clearErrorForTranslationItem(id, key);
  };

  const clearData = () => {
    setTranslationData(initialTranslationData);
    setErrorTranslationData([]);
    if (showingConceptId) {
      setConceptId('');
      setErrorConceptId([]);
    }
  };

  const requestClose = () => {
    clearData();
    onCancel();
  };

  const errorsForTranslationItem = (id) => {
    if (isEmpty(errorTranslationData)) return {};

    return errorTranslationData.find((e) => e.id === id) || {};
  };

  const errorsForConceptId = () => (isEmpty(errorConceptId) ? {} : errorConceptId);

  const clearErrorForTranslationItem = (id, key) => {
    const otherErrors = errorTranslationData.filter((error) => error.id !== id);
    const thisError = errorsForTranslationItem(id);
    thisError[key] = [];

    setErrorTranslationData([...otherErrors, thisError]);
  };

  const updateConceptId = (value) => {
    setConceptId(value);
    setErrorConceptId([]);
  };

  const handleClickConceptIDTooltip = () => {
    navigate({url: TAXONOMY_VIEWER_URL});
  };

  const validateForm = () => {
    const translationErrors = [];
    setErrorTranslationData(translationErrors);

    translationData.forEach((t) => {
      const labelErrors = [];
      const languageErrors = [];

      const trimmedLabel = trim(t.label);
      if (trimmedLabel === '') labelErrors.push('empty');
      if (trimmedLabel.length > LABEL_MAX_LENGTH) labelErrors.push('too_long');
      if (t.language === null) languageErrors.push('empty');

      if (labelErrors.length > 0 || languageErrors.length > 0) {
        translationErrors.push({ id: t.id, label: labelErrors, language: languageErrors });
      }
    });
    setErrorTranslationData(translationErrors);

    const conceptIdErrors = [];
    if (showingConceptId) {
      setErrorConceptId(conceptIdErrors);
      const trimmedConceptId = trim(conceptId);
      if (trimmedConceptId === '') conceptIdErrors.push('empty');
      if (trimmedConceptId.length > 0 && trimmedConceptId.length !== CONCEPT_ID_LENGTH) conceptIdErrors.push('wrong_length');
      setErrorConceptId(conceptIdErrors);
    }

    return ({
      translationErrors,
      conceptIdErrors
    });
  };

  const requestSave = () => {
    const errors = validateForm();

    if (isEmpty(errors.translationErrors) && isEmpty(errors.conceptIdErrors)) {
      setIsSaving(true);
      saveCategory();
    } else {
      // Move focus to the first input field that has a form validation error
      setErrorTranslationDataOnSave(errors);
    }
  };

  // Transform new category data for BFF:
  const formattedCategoryDataForPost = () => {
    const labels = translationData.map((d) => ({ locale: d.language.id, label: d.label }));

    return showingConceptId ? ({
      category_group_id: categoryGroupId,
      name: labels,
      concept_id: conceptId
    }) : ({
      category_group_id: categoryGroupId,
      name: labels
    });
  };

  const setStateFromResponse = (response) => {
    // updates `translationData` and `errorTranslationData`.
    // comes up with ids for each item.
    const newTranslationData = [];
    const newErrorTranslationData = [];
    let newConceptId = '';
    const newErrorConceptId = [];

    response.name.forEach((t) => {
      const id = uniqueId('bff-');
      newTranslationData.push({
        id,
        label: t.label,
        language: languages.find((l) => l.id === t.locale),
      });
      if (t.errors) {
        newErrorTranslationData.push({
          id,
          label: t.errors,
        });
      }
    });
    setTranslationData(newTranslationData);
    setErrorTranslationData(newErrorTranslationData);

    if (!response.concept_id) {
      newConceptId = null;
    } else if (typeof response.concept_id === 'string' || response.concept_id instanceof String) {
      newConceptId = response.concept_id;
    } else {
      newErrorConceptId.push(response.concept_id.errors);
      newConceptId = response.concept_id.concept_id;
    }
    setConceptId(newConceptId);
    setErrorConceptId(newErrorConceptId);
  };

  const notifyTopLevelErrors = (errors) => {
    if (isEmpty(errors)) return;

    let linkLabel = '';
    let linkURL = '';
    const errorMessages = errors.map((key) => (
      tCat(`errors.${key}`)
    )).join(' ');

    if (errors.includes('limit_reached')) {
      linkLabel = tCat('errors.limit_reached_tell_me_more');
      linkURL = tCat('errors.limit_reached_tell_me_more_url');
    }

    onCategorySavedError(errorMessages, linkLabel, linkURL);
  };

  const saveCategory = () => {
    let url = editCategoryData ? `${API_CATEGORIES_ENDPOINT}/${editCategoryData.id}` : API_CATEGORIES_ENDPOINT
    const method = editCategoryData ? 'put' : 'post'

    railsFetch({ url: url, method: method, data: formattedCategoryDataForPost() })
      .then((response) => {
        clearData();

        // Response might be empty on edits
        let sampleLabel;
        if (Object.keys(response).length != 0) {
          sampleLabel = response.name.find((t) => t.locale === 'en').label;
        }
        onCategorySaved(sampleLabel, editCategoryData);
      })
      .catch((response) => {
        const code = response.railsFetchStatusCode;
        switch (true) {
          case (code >= 400 && code < 500):
            notifyTopLevelErrors(response.errors);
            setStateFromResponse(response);
            break;
          default:
            notifyTopLevelErrors(['creating_category_failed']);
        }
      })
      .finally(() => {
        if (mounted.current) {
          setIsSaving(false);
        }
      });
  };

  return (
    <Modal
      id={id}
      isOpen={isOpen}
      footer={[
        <Button
          label={tModal('cancel_button')}
          variant="neutral"
          onClick={requestClose}
          key="btn-cancel"
        />,
        <Button
          label={tModal('save_button')}
          variant="brand"
          onClick={requestSave}
          key="btn-save"
          spinner={isSaving}
        />,
      ]}
      onRequestClose={requestClose}
      heading={editCategoryData ? tModal(`title.edit`) : tModal(`title.${categoryGroupAPIName}`)}
      size="small"
      contentClassName="category-entry-modal-contents"
    >
      <div className="slds-p-around_x-large">
        {translationData && translationData.map((translation, index) =>
          <CategoryTranslation
            key={translation.id}
            id={translation.id}
            groupAPIName={categoryGroupAPIName}
            label={translation.label}
            labelErrors={errorsForTranslationItem(translation.id).label}
            labelMaxLength={LABEL_MAX_LENGTH}
            language={translation.language}
            languageErrors={errorsForTranslationItem(translation.id).language}
            languageOptions={translation.language ?
              [...availableLanguages, translation.language] :
              availableLanguages}
            showFieldLabels={index === 0}
            showRemoveButton={index > 0 && !editCategoryData}
            onUpdateLabel={updateField}
            onUpdateLanguage={updateField}
            onRemove={removeTranslation}
            readOnlyLanguage={index === 0 || (!!editCategoryData && translationData.length === languages.length)}
            publicTrailmaker={publicTrailmaker}
          />
        )}
        <div className="slds-text-body_regular tds-text-size_4">
          {tModal('body')}
          {' '}
          <a href="https://help.salesforce.com/articleView?id=mth_add_filter_to_products_category.htm&type=5" target="_blank" rel="noreferrer noopener">
            {tModal('tell_me_more')}
          </a>
        </div>
        {showingConceptId &&
        <div className="slds-size_1-of-2">
          <Input
            id="input-concept-id"
            label={tModal('concept_id_label')}
            placeholder={tModal('concept_id_placeholder')}
            value={conceptId}
            onChange={(_, {value}) => updateConceptId(value)}
            maxLength={CONCEPT_ID_LENGTH.toString()}
            minLength={CONCEPT_ID_LENGTH.toString()}
            fieldLevelHelpTooltip={
              <Tooltip
                id={'input-concept-id-label-tooltip'}
                align="top left"
                content={tModal('concept_id_tooltip')}
                onClickTrigger={handleClickConceptIDTooltip}
                variant="learnMore"
              />
            }
            errorText={conceptIDErrorText(errorsForConceptId(), {fixed_length: CONCEPT_ID_LENGTH})}
            required
          />
        </div>
        }
        {(translationData.length < languages.length) &&
        <div className="slds-text-align_center slds-m-vertical_medium">
          <Button
            variant="neutral"
            onClick={addTranslation}
            id="add-translation-button"
            label={tModal('add_translation_button')}
          />
        </div>
        }
      </div>
    </Modal>
  );
};

CategoryEntryModal.propTypes = {
  categoryGroupAPIName: PropTypes.string.isRequired,
  categoryGroupId: PropTypes.string.isRequired,
  id: PropTypes.string,
  isOpen: PropTypes.bool,
  languages: PropTypes.arrayOf(languageShape),
  onCancel: PropTypes.func.isRequired,
  onCategorySaved: PropTypes.func.isRequired,
  onCategorySavedError: PropTypes.func.isRequired,
  showingConceptId: PropTypes.bool,
};

CategoryEntryModal.defaultProps = {
  isOpen: false,
};

export default CategoryEntryModal;
