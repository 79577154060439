import React from 'react';
import PropTypes from 'prop-types';
import debounce from 'lodash/debounce';
import pick from 'lodash/pick';
import { Input } from '@devforce/tds-react';
import { InputIcon } from '@salesforce/design-system-react';
import provideContext from 'lib/provideContext';

import { store } from 'reducers';
import wrapProvider from 'lib/wrapProvider';
import railsFetch from 'lib/railsFetch';
import { initManifest, deleteManifestItem } from 'actions/trailmakerActions';
import { buildScopeTranslate } from 'lib/I18n';
import IconContext from 'components/utils/IconContext';
import ReleaseManifestItemRow from 'components/trailmaker/ReleaseManifestItemRow';
import emptyListImage from 'images/trailmaker/release-empty.svg';

const WAIT = 500; // 500 Milliseconds

// Curry translation
const tRelease = buildScopeTranslate('views.trailmaker.release');
const tManifest = buildScopeTranslate('views.trailmaker.manifest');
const tActions = buildScopeTranslate('views.trailmaker.actions');

export class ReleaseManifest extends React.PureComponent {
  static propTypes = {
    items: PropTypes.array.isRequired,
    form: PropTypes.shape({
      types: PropTypes.arrayOf(PropTypes.string),
      operations: PropTypes.arrayOf(PropTypes.string)
    }),
    removeItemHref: PropTypes.string,
    removeItem: PropTypes.func,
    canAddManifestItem: PropTypes.bool,
    canOpenManifestItemMenu: PropTypes.bool,
  };

  static defaultProps = {
    canAddManifestItem: false,
    canOpenManifestItemMenu: false,
  };

  state = {items: this.props.items, search: ''};

  componentWillReceiveProps(nextProps) {
    if (nextProps.items !== this.props.items) {
      this.setState({items: this.filterItems(nextProps.items)});
    }
  }

  onSearch = (e) => {
    this.setState({search: e.target.value});
    if (e.target.value === '') {
      this.setState({items: this.props.items});
    } else {
      this.delaySearch();
    }
  };

  onRemoveItem = (item) => {
    const {removeItem, removeItemHref} = this.props;

    removeItem({url: removeItemHref, item});
  };


  delaySearch = debounce(() => {
    this.setState({items: this.filterItems(this.props.items)});
  }, WAIT);

  filterItems(items) {
    const q = (this.state.search || '').trim().toLowerCase();
    const selector = ({api_name}) => (api_name || '').toLowerCase().indexOf(q) > -1;

    return (q === '') ? items : items.filter(selector);
  }

  renderHeader(items) {
    return (
      <div className="slds-grid slds-grid_vertical-align-center slds-wrap">
        <div className="slds-max-medium-size_1-of-1">
          <h3 className="slds-text-heading_medium th-text--bold th-color--secondary">
            {tRelease('items_in_release', {count: items.length})}
          </h3>
        </div>
        <div className="slds-col slds-grid slds-grid_align-end">
          <Input
            id="search-manifest-items"
            data-test-search-manifest-items
            iconLeft={<InputIcon name="search" category="utility" />}
            assistiveText={{label: tManifest('search')}}
            className="slds-m-bottom_none"
            placeholder={tManifest('search')}
            value={this.state.search}
            onChange={this.onSearch}
          />
        </div>
      </div>
    );
  }

  renderRow = (item) => (
    <ReleaseManifestItemRow
      {...item}
      {...this.props.form}
      onDelete={this.onRemoveItem}
      key={`${item.type}_${item.api_name}`}
      actionMenuDisabled={!this.props.canOpenManifestItemMenu}
    />
  );

  renderEmpty() {
    return (
      <div className="th-max-width--large">
        <img
          alt={tManifest('get_started_header')}
          className="slds-m-top_x-large"
          data-test-empty-list
          src={emptyListImage}
        />
        <div className="slds-text-align_center slds-p-top_x-large th-text--medium">
          { this.state.search ? tManifest('empty_search_header') : tManifest('get_started_header')}
        </div>
      </div>
    );
  }

  renderTable(items) {
    return (
      <table data-test-manifest-items className="slds-table slds-table_bordered slds-table_cell-buffer slds-m-bottom_large">
        <thead>
          <tr className="slds-text-title--caps">
            <th style={{width: '40%'}}><div className="slds-p-vertical_x-small">{tRelease('api_name')}</div></th>
            <th>{tManifest('type')}</th>
            <th>{tManifest('operation')}</th>
            <th style={{width: '100px'}}>{tRelease('actions')}</th>
          </tr>
        </thead>
        <tbody>{items.map(this.renderRow)}</tbody>
      </table>
    );
  }

  render() {
    const {items} = this.state;

    return (
      <IconContext>
        <div className="slds-container_x-large slds-container_center slds-p-horizontal_medium">
          {this.renderHeader(items)}

          <div className="slds-text-align_center slds-m-vertical_large">
            {(items.length === 0) ? this.renderEmpty() : this.renderTable(items)}
          </div>
        </div>
      </IconContext>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    removeItem({url, item}) {
      railsFetch({
        url,
        method: 'delete',
        data: {items: [item]}
      }).then(() => {
        dispatch(deleteManifestItem(item));
      }).catch(() => {
        alert(tActions('delete_error', {item: item.api_name}));
      });
    }
  };
}

function mapStateToProps(state) {
  // For some reason, this function is called twice, and
  // the state only has the items the second time around.
  if (!state.trailmaker.release.items) return {};

  return pick(state.trailmaker.release, [
    'canAddManifestItem',
    'canOpenManifestItemMenu',
    'items',
    'removeItemHref',
  ]);
}

export default provideContext(
  wrapProvider({
    store,
    initAction: initManifest,
    mapStateToProps,
    mapDispatchToProps,
  })(ReleaseManifest)
);
