import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import snakeCase from 'lodash/snakeCase';
import Toggle from 'components/form/Toggle';
import Select from 'components/form/Select';
import ToolTip from 'components/slds/ToolTip';

export default class FormElement extends React.PureComponent {
  static propTypes = {
    label: PropTypes.string,
    name: PropTypes.string.isRequired,
    type: PropTypes.string,
    errors: PropTypes.array,
    valueType: PropTypes.string,
    valueProps: PropTypes.object,
    prefix: PropTypes.string,
    suffix: PropTypes.string,
    onLabel: PropTypes.string,
    offLabel: PropTypes.string,
    checked: PropTypes.bool,
    defaultChecked: PropTypes.bool,
    isEditing: PropTypes.bool,
    value: PropTypes.any,
    defaultValue: PropTypes.any,
    editToolTip: PropTypes.string,
    toolTip: PropTypes.string,
    onFocus: PropTypes.func,
    focusOnMount: PropTypes.bool
  };

  static defaultProps = {
    isEditing: true,
    type: 'text',
    valueProps: {},
    focusOnMount: false
  };

  componentDidMount() {
    const { focusOnMount } = this.props;

    if (focusOnMount && this.inputField) {
      this.inputField.focus();
    }
  }

  renderInput({
    type,
    valueType,
    hidePublic,
    valueProps,
    fullWidth,
    onFocus,
    defaultValue,
    ...fieldProps
  }) { /* eslint-disable no-unused-vars */
    switch (type.toLowerCase()) {
      case 'textarea': {
        return (
          <textarea
            className="slds-textarea M(0)"
            onFocus={() => { if (onFocus) onFocus(defaultValue); }}
            defaultValue={defaultValue}
            {...fieldProps}
          />
        );
      }

      case 'toggle': {
        return (
          <Toggle
            onFocus={() => { if (onFocus) onFocus(defaultValue); }}
            defaultValue={defaultValue}
            {...fieldProps}
          />
        );
      }

      case 'select': {
        return (
          <Select
            onFocus={() => { if (onFocus) onFocus(defaultValue); }}
            defaultValue={defaultValue}
            {...fieldProps} />
        );
      }

      default: {
        return (
          <input
            ref={(c) => { this.inputField = c; }}
            type={type || 'text'}
            className="slds-input M(0)"
            onFocus={() => { if (onFocus) onFocus(defaultValue); }}
            defaultValue={defaultValue}
            {...fieldProps}
          />
        );
      }
    }
  }

  renderValue() {
    const {type, value, prefix = '', suffix = '', valueType, defaultValue, valueProps} = this.props;
    let currentValue = `${prefix}${value || defaultValue}${suffix}`;

    switch ((valueType || type).toLowerCase()) {
      case 'url': {
        if (!/^http/i.test(currentValue)) currentValue = `http://${currentValue}`;

        return (
          <a href={currentValue} className="slds-truncate D(ib)" {...valueProps}>
            {currentValue}
          </a>
        );
      }

      case 'toggle': {
        const {checked, defaultChecked, onLabel, offLabel} = this.props;
        const label = (checked || defaultChecked) ? onLabel : offLabel;

        return <span {...valueProps}>{label}</span>;
      }

      default: {
        return <span {...valueProps}>{currentValue}</span>;
      }
    }
  }

  renderAddon(text) {
    return text && (<span className="slds-form-element__addon tds-text-size_4 slds-m-around_none">{text}</span>);
  }

  renderHelp = (msg, idx) => (
    <div className="slds-form-element__help" key={`msg_${idx}`}>{msg}</div>
  );

  render() {
    const {
      label,
      isEditing,
      prefix,
      suffix,
      errors,
      toolTip,
      editToolTip,
      focusOnMount,
      ...otherProps
    } = this.props;
    const id = snakeCase(otherProps.name);
    const controlClassName = classNames('slds-form-element__control', {
      'slds-input-has-fixed-addon': (prefix || suffix)
    });

    return (
      <div className={classNames('slds-form-element', {'slds-has-error': errors})}>
        <label className="slds-form-element__label" htmlFor={id}>{label}</label>
        <ToolTip contents={isEditing ? editToolTip : toolTip} id={id} />
        <div className={controlClassName}>
          {isEditing && this.renderAddon(prefix)}
          {isEditing ? this.renderInput({...otherProps, id}) : this.renderValue()}
          {isEditing && this.renderAddon(suffix)}
        </div>
        {isEditing && errors && errors.map(this.renderHelp)}
      </div>
    );
  }
}
