/* eslint-disable react/no-danger */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Icon from 'components/utils/Icon';

export default class ToolTip extends Component {
  static propTypes = {
    contents: PropTypes.string,
    id: PropTypes.string,
  };

  constructor(props) {
    super(props);
    this.state = { visible: false };
  }

  handleMouse = () => {
    this.setState((prevState) => ({
      visible: !prevState.visible
    }));
  };

  render() {
    if (!this.props.contents) return null;
    return (
      <div
        onMouseOver={this.handleMouse}
        onMouseOut={this.handleMouse}
        style={{display: 'inline-block'}}
        className="slds-is-relative"
      >
        <Icon
          icon="info"
          type="utility"
          className="slds-button__icon"
        />
        <div
          className={`slds-popover slds-popover_tooltip slds-nubbin_bottom-left ${this.state.visible ? 'slds-rise-from-ground' : 'slds-fall-into-ground'}`}
          id={this.props.id}
          style={{top: '-36px', left: '-32px'}}
          role="tooltip">
          <div className="slds-popover__body slds-text-longform">
            <p dangerouslySetInnerHTML={{__html: this.props.contents}} />
          </div>
        </div>
      </div>
    );
  }
}
