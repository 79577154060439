import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Settings } from '@salesforce/design-system-react';
import {
  Button,
  DataTable,
  DataTableCell,
  DataTableColumn,
  DropdownMenu,
  Icon,
  Tooltip
} from '@devforce/tds-react';
import CategoryDeleteModal from './CategoryDeleteModal';
import { I18n, buildScopeTranslate } from '../../../../lib/I18n';

const tCat = buildScopeTranslate('views.trailmaker.settings.category.table');

const valueInLocale = (locale, translations) => {
  // Prefer value in current locale. Fall back to English if not available.
  const t = translations.find((translation) => translation.locale === locale) ||
    translations.find((translation) => translation.locale === 'en');
  return t ? t.value : '';
};

const prepareCategories = (locale, categories, limit) => {
  const preparedCategories = [];

  for (let i = 0; i < limit; i++) { // eslint-disable-line no-plusplus
    preparedCategories[i] = {
      id: categories[i].id,
      name: valueInLocale(locale, categories[i].name),
      protected: !!categories[i].protected,
    };
  }

  return preparedCategories;
};

const ActionsDataCell = ({
  children,
  item,
  onRequestDelete,
  onRequestEdit,
  groupAPIName,
  publicTrailmaker,
  ...rest
}) => {
  const tooltipContent = () => (item.protected ? tCat(`actions.delete_tooltip.${groupAPIName}`) : '');

  const MENU_OPTIONS_DEFAULT = [
    {
      label: tCat('actions.delete'),
      value: 'delete',
      disabled: !!item.protected,
      tooltipContent: tooltipContent(),
    }
  ];
  const MENU_OPTIONS_PUBLIC_TRAILHEAD = [
    {
      label: tCat('actions.edit'),
      value: 'edit',
    },
    ...MENU_OPTIONS_DEFAULT,
  ]
  const options = publicTrailmaker ? MENU_OPTIONS_PUBLIC_TRAILHEAD : MENU_OPTIONS_DEFAULT;

  return (
    <DataTableCell {...rest}>
      <DropdownMenu
        assistiveText={{
          icon: 'More Options'
        }}
        iconCategory="utility"
        iconName="down"
        iconVariant="border-filled"
        buttonClassName="tds-button_icon"
        onSelect={(opt) => {
          switch (opt.value) {
            case 'edit':
              if (item.protected) break;
              onRequestEdit(item.id, item.name, !!item.protected);
              break;
            case 'delete':
              if (item.protected) break;
              onRequestDelete(item.id, item.name, !!item.protected);
              break;
            default:
              break;
          }
        }}
        width="xx-small"
        align="right"
        options={options}
        menuPosition="overflowBoundaryElement"
        tooltipMenuItem={<Tooltip position="overflowBoundaryElement" />}
      />
    </DataTableCell>
  );
};

ActionsDataCell.displayName = DataTableCell.displayName;

const CategoryTable = ({
  groupId,
  groupAPIName,
  name,
  categories,
  onDeleteCategory,
  onAddCategory,
  onEditCategory,
  canAddCategory,
  publicTrailmaker,
}) => {
  const COLLAPSED_MAX_ITEMS = 10;

  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [itemRequestedForDeletion, setItemRequestedForDeletion] = useState(null);
  const [isDeleting, setIsDeleting] = useState(false);
  const [isCollapsed, setIsCollapsed] = useState(true);

  const onRequestDeleteCategory = (id, categoryName, isProtected) => {
    setItemRequestedForDeletion({ id, categoryName, isProtected });
    setIsDeleteModalOpen(true);
  };

  const onCancelDeleteCategory = () => {
    setItemRequestedForDeletion(null);
    setIsDeleteModalOpen(false);
  };

  const onCompletedDeleteCategory = () => {
    setIsDeleteModalOpen(false);
    setIsDeleting(false);
  };

  const onConfirmDeleteCategory = () => {
    setIsDeleting(true);
    onDeleteCategory(
      itemRequestedForDeletion.id,
      itemRequestedForDeletion.categoryName,
      onCompletedDeleteCategory
    );
  };

  const onRequestEditCategory = (id) => {
    onEditCategory(id);
  };

  const rowLimit = () => {
    if (categories.length < COLLAPSED_MAX_ITEMS) return categories.length;
    if (isCollapsed) return COLLAPSED_MAX_ITEMS;
    return categories.length;
  };

  return (
    <div className="slds-m-bottom_x-large">
      <div className="slds-grid slds-grid_vertical-align-center slds-m-bottom_medium">
        <div className="slds-col slds-grid slds-grid_vertical-align-center">
          <Icon
            assistiveText={{ label: 'User' }}
            category="standard"
            name="quotes"
            className="tds-bg_salesforce"
          />
          <p className="slds-text-heading_small slds-m-left_small">{valueInLocale(I18n.locale, name)}</p>
        </div>
        <div className="slds-grid slds-grid_vertical-align-center slds-grid_align-end">
          <Button
            label={tCat('actions.new_filter')}
            variant="neutral"
            disabled={!canAddCategory}
            onClick={() => onAddCategory(groupId)}
          />
          {!canAddCategory &&
          <Tooltip
            id="new-filter-button-tooltip"
            align="top"
            content={tCat(`actions.new_filter_limit_reached.${groupAPIName}`)}
          >
            <a href="#" className="slds-m-left_x-small" tabIndex="0">
              <Icon
                assistiveText={{label: 'Tooltip with icon'}}
                category="utility"
                name="info"
                size="x-small"
              />
            </a>
          </Tooltip>
          }
        </div>
      </div>
      <DataTable
        items={prepareCategories(
          I18n.locale,
          categories,
          rowLimit()
        )}
        noRowHover
        fixedLayout
      >
        <DataTableColumn label={tCat('columns.name')} property="name" />
        <DataTableColumn label={tCat('columns.actions')} width="6rem">
          <ActionsDataCell
            title={tCat('columns.actions')}
            onRequestDelete={onRequestDeleteCategory}
            onRequestEdit={onRequestEditCategory}
            groupAPIName={groupAPIName}
            publicTrailmaker={publicTrailmaker}
          />
        </DataTableColumn>
      </DataTable>
      {categories.length > COLLAPSED_MAX_ITEMS &&
      <Button
        label={isCollapsed ? tCat('actions.view_all') : tCat('actions.view_less')}
        onClick={() => setIsCollapsed(!isCollapsed)}
        variant="neutral"
        className="slds-m-top_large slds-align_absolute-center"
      />
      }
      <CategoryDeleteModal
        isOpen={isDeleteModalOpen}
        categoryGroupAPIName={groupAPIName}
        categoryId={itemRequestedForDeletion ? itemRequestedForDeletion.id : ''}
        categoryName={itemRequestedForDeletion ? itemRequestedForDeletion.categoryName : ''}
        isCategoryProtected={
          itemRequestedForDeletion ? itemRequestedForDeletion.isProtected : false
        }
        onDelete={onConfirmDeleteCategory}
        isDeleting={isDeleting}
        onRequestClose={onCancelDeleteCategory}
      />
    </div>
  );
};

const translationsShape = PropTypes.shape({
  locale: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
});

CategoryTable.propTypes = {
  groupId: PropTypes.string.isRequired,
  groupAPIName: PropTypes.string.isRequired,
  name: PropTypes.arrayOf(translationsShape).isRequired,
  categories: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.arrayOf(translationsShape).isRequired,
      protected: PropTypes.bool,
    })
  ).isRequired,
  onDeleteCategory: PropTypes.func.isRequired,
  onAddCategory: PropTypes.func.isRequired,
  canAddCategory: PropTypes.bool.isRequired,
};

export default CategoryTable;
