import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Icon, Popover } from '@salesforce/design-system-react';
import { t } from 'lib/I18n';

export default class UnitsPopover extends Component {
  static propTypes = {
    units: PropTypes.array.isRequired,
    headingTitle: PropTypes.string.isRequired,
    headingImgUrl: PropTypes.string.isRequired,
    children: PropTypes.node.isRequired,
    className: PropTypes.string,
    align: PropTypes.string,
    contentPath: PropTypes.string,
    offset: PropTypes.string,
  }

  static defaultProps = {
    align: 'bottom right',
    className: '',
    offset: '-5px 0',
  }

  setPopoverState(isVisible) {
    this.setState({
      triggerButtonOpen: isVisible
    });
  }

  render() {
    const {
      align,
      units,
      headingTitle,
      headingImgUrl,
      children,
      className,
      contentPath,
      offset,
    } = this.props;

    // NOTE: heading prop is supposed to take text, not markup
    // so it gets yielded to an H2
    return (
      <Popover
        offset={offset}
        align={align}
        onClose={() => this.setPopoverState(false)}
        onOpen={() => this.setPopoverState(true)}
        className={`${className} th-popover th-popover--units th-animation--popin`}
        heading={
          <div className="slds-grid slds-grid_vertical-align-center">
            <a
              href={contentPath}
              className="slds-m-right_small slds-shrink-none"
              aria-hidden="true"
              tabIndex="-1"
              style={{ width: '35px' }}
            >
              <img
                alt="content icon"
                width="35"
                height="35"
                src={headingImgUrl}
              />
            </a>
            <div className="slds-text-align_left th-text--default th-text--bold">
              <a href={contentPath}>
                {headingTitle}
              </a>
            </div>
          </div>
        }
        body={
          <ul className="th-popover-list--units">
            {units.map((unit) =>
              <li
                key={unit.title}
              >
                <a
                  tabIndex="0"
                  href={unit.path}
                  className={unit.is_current_page ? 'th-popover-list--units__current_item' : ''}
                  aria-current={unit.is_current_page ? 'location' : false}
                >
                  <div className="th-popover-list--units__description">
                    <div className="th-popover-list--units__title th-text--small">
                      {unit.title}
                    </div>
                    <div className="th-popover-list--units__subtext th-text--x-small">
                      <span>
                        ~
                      </span>
                      {t('views.trailhead.module.min_to_complete', { min: unit.time })}
                    </div>
                  </div>
                  <div className="slds-float--right">
                    {unit.isComplete ?
                      <span>
                        <div className="th-icon--completed">
                          <Icon
                            category="utility"
                            name="check"
                            size="x-small"
                            style={{
                              fill: '#fff'
                            }}
                          />
                        </div>
                        <div className="slds-assistive-text">
                          {t('content.unitCompleted')}
                        </div>
                      </span>
                      :
                      <span>
                        <div className="th-icon--in-progress">
                          <Icon
                            category="utility"
                            name="forward"
                            size="x-small"
                            style={{
                              fill: '#666'
                            }}
                          />
                        </div>
                        <div className="slds-assistive-text">
                          {t('content.unitIncomplete')}
                        </div>
                      </span>
                    }
                  </div>
                </a>
              </li>
            )}
          </ul>
        }
      >
        {children}
      </Popover>
    );
  }
}
