/* eslint-disable no-useless-escape */
import React from 'react';
import ReactDOM from 'react-dom';
import * as actions from 'actions/trailmix_action_creators';
import {store} from 'reducers';
import PropTypes from 'prop-types';
import wrapProvider from 'lib/wrapProvider';
import provideContext from 'lib/provideContext';
import {Icon, Input, Textarea, Tooltip} from '@devforce/tds-react';
import IconContext from 'components/utils/IconContext';
import { IconSettings } from '@salesforce/design-system-react';
import {t} from 'lib/I18n';
import TrailmixAssetModal from './TrailmixAssetModal';
import TrailmixSaveButton from './TrailmixSaveButton';

class TrailmixEditForm extends React.Component {
  static propTypes = {
    title: PropTypes.string,
    description: PropTypes.string,
    onTrailmixEditTitle: PropTypes.func,
    onTrailmixEditSlug: PropTypes.func,
    onTrailmixEditDescription: PropTypes.func,
    slug: PropTypes.string,
    creator: PropTypes.object,
    uid: PropTypes.string,
    // These are used by initAction
    /* eslint-disable react/no-unused-prop-types */
    trailmixPath: PropTypes.string,
    suggestions: PropTypes.array,
    itemIndexPaths: PropTypes.object,
    items: PropTypes.array,
    deletedItems: PropTypes.array,
    avatar: PropTypes.object,
    defaultAvatarPath: PropTypes.string,
    /* eslint-enable react/no-unused-prop-types */
  }

  static defaultProps = {
    title: '',
    description: '',
    slug: '',
  }

  onSlugFocus() {
    const detail = {
      heading: t('views.trailmixes.create.editing_slug'),
      variant: 'info'
    };

    window.dispatchEvent(
      new CustomEvent('showToast', {detail})
    );
  }

  onAvatarModalClose = () => {
    const modalContainer = document.querySelector('#avatar-modal');
    modalContainer.className = 'slds-hide';
  }

  onAvatarClick = () => {
    const modalContainer = document.querySelector('#avatar-modal');
    if (modalContainer.classList.contains('slds-hide')) {
      modalContainer.classList.remove('slds-hide');
    } else {
      const avatarModal = React.createElement(
        TrailmixAssetModal, {
          previewImg: this.currentAvatar(),
          defaultAssetPath: this.props.defaultAvatarPath,
          isOpen: true,
          type: 'avatar',
          uploader: this.props.avatar,
          trailmixProps: {
            creator_slug: this.props.creator.slug,
            trailmix_slug: this.props.slug
          },
          onClose: this.onAvatarModalClose,
          modalTitle: 'edit_avatar',
          recommendedAssetSpecs: 'recommended_avatar_dimensions'
        }
      );

      const avatarContainer = document.querySelector('#avatar-modal');
      ReactDOM.render(avatarModal, avatarContainer);
    }
  }

  inputLostFocus = (field, onChange) => {
    onChange(this.props[field].trim());
  };

  currentAvatar = () => {
    const avatarUrl = this.props.avatar.url;
    if (avatarUrl) {
      return avatarUrl;
    }

    // Return default creator profile image
    return this.props.defaultAvatarPath;
  }

  creator_name = (creator) => {
    if (creator.profilePath === '' || creator.profilePath === null) {
      return creator.fullName;
    }

    return (
      <a href={creator.profilePath}>
        {creator.fullName}
      </a>
    );
  }

  render() {
    const title = 'title';
    const description = 'description';
    const slug = 'slug';
    const creator = this.props.creator;

    return (
      <div className="slds-container_center slds-p-horizontal_large">
        <article className="slds-card slds-m-top--none">
          <div className="slds-card__header">
            <div
              className="slds-box--border slds-align_absolute-center trailmix-profile">
              <a
                onClick={this.onAvatarClick}
                role="link"
                tabIndex={0}
              >
                <span className="slds-icon_container slds-icon_container--circle trailmix-avatar-upload">
                  <IconContext>
                    <Icon
                      assistiveText={{ label: 'Image' }}
                      category="utility"
                      name="image"
                      size="x-small"
                    />
                  </IconContext>
                </span>
                <img
                  src={this.currentAvatar()}
                  className="trailmix-avatar"
                  id="trailmix-avatar"
                  alt="Trailmix Avatar"
                />
              </a>
              <div id="avatar-modal" />
            </div>
          </div>
          <div className="slds-card__body slds-card__body_inner">
            <div className="slds-text-align--center slds-text-heading_medium slds-m-bottom--small">
              {t('views.trailhead.trailmix.type')}
              { this.creator_name(creator) }
            </div>
            <Input
              value={this.props.title}
              id="trailmix-title"
              label={t('views.trailmixes.create.name')}
              placeholder={t('views.trailmixes.create.name_edit_placeholder')}
              className="slds-m-bottom_small"
              maxLength="80"
              required={true}
              onChange={(e) => this.props.onTrailmixEditTitle(e.target.value)}
              onBlur={() => this.inputLostFocus(title, this.props.onTrailmixEditTitle)}
              aria-required
            />
            <Textarea
              id="trailmix-description"
              value={this.props.description}
              label={t('views.trailmixes.create.description')}
              placeholder={t('views.trailmixes.create.description_edit_placeholder')}
              name="required-textarea-error"
              classNameContainer="slds-m-bottom_small"
              className="slds-show"
              maxLength="140"
              required={true}
              onChange={(e) => this.props.onTrailmixEditDescription(e.target.value)}
              onBlur={() => this.inputLostFocus(description, this.props.onTrailmixEditDescription)}
              aria-required
            />
            <IconSettings iconPath="/assets/icons">
              <Input
                id="trailmix-slug"
                label={t('views.trailmixes.create.trailmix_name')}
                placeholder={t('views.trailmixes.create.slug_edit_placeholder')}
                maxLength="64"
                required={true}
                value={this.props.slug}
                onChange={(e) => this.props.onTrailmixEditSlug(e.target.value)}
                onBlur={() => this.inputLostFocus(slug, this.props.onTrailmixEditSlug)}
                onFocus={() => this.onSlugFocus()}
                fieldLevelHelpTooltip={
                  <Tooltip
                    id="slug-edit-help-tooltip"
                    content={t('views.trailmixes.create.slug_edit_info')}
                    hoverCloseDelay={50}
                  >
                    <div tabIndex="0">
                      <Icon
                        category="utility"
                        name="info"
                        size="x-small"
                      />
                    </div>
                  </Tooltip>
                }
                aria-required
              />
            </IconSettings>
          </div>
          <footer className="slds-card__footer">
            <TrailmixSaveButton {...this.props} />
          </footer>
        </article>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  onTrailmixEditTitle: (value) => {
    dispatch(actions.trailmixEditTitle(value));
  },
  onTrailmixEditDescription: (value) => {
    dispatch(actions.trailmixEditDescription(value));
  },
  onTrailmixEditSlug: (value) => {
    dispatch(actions.trailmixEditSlug(value));
  }
});

export default provideContext(
  wrapProvider({
    store,
    initAction: actions.initTrailmix,
    mapDispatchToProps,
    mapStateToProps: (state) => ({
      title: state.trailmixes.title,
      description: state.trailmixes.description,
      slug: state.trailmixes.slug,
    }),
  })(TrailmixEditForm)
);
