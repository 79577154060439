import React from 'react';
import PropTypes from 'prop-types';
import flatten from 'lodash/flatten';
import isEmpty from 'lodash/isEmpty';
import pick from 'lodash/pick';
import { buildScopeTranslate } from 'lib/I18n';
import { Alert, Button, ButtonGroup, ButtonLink, Combobox, Icon, InlineEdit, Toast } from '@devforce/tds-react';

import navigate from 'lib/navigate';
import IconContext from 'components/utils/IconContext';
import ReleaseDeleteModal from 'components/trailmaker/ReleaseDeleteModal';
import ReleaseHealthPoller from 'components/trailmaker/ReleaseHealthPoller';
import optionsShape from 'shapes/dsr/combobox/optionsShape';

const tRelease = buildScopeTranslate('views.trailmaker.release');
const tActions = buildScopeTranslate('views.trailmaker.actions');
const maxApiNameLength = 32;

const HealthCheckProgress = {
  Loading: 1,
  Error: 2,
  Complete: 3,
};

export default class ReleaseDetails extends React.PureComponent {
  static propTypes = {
    api_name: PropTypes.string.isRequired,
    addItemsHref: PropTypes.string.isRequired,
    canAddManifestItem: PropTypes.bool,
    canEditApiName: PropTypes.bool,
    canEditLabel: PropTypes.bool,
    canPreview: PropTypes.bool,
    canPublish: PropTypes.bool,
    canDelete: PropTypes.bool,
    items: PropTypes.arrayOf(PropTypes.object).isRequired,
    label: PropTypes.string.isRequired,
    nextStates: optionsShape.isRequired,
    previewReleaseHref: PropTypes.string.isRequired,
    publishReleaseHref: PropTypes.string.isRequired,
    releaseHref: PropTypes.string.isRequired,
    releaseIssuesHref: PropTypes.string,
    save: PropTypes.func,
    state: PropTypes.string.isRequired,
    releaseHealthCheckJobId: PropTypes.string,
  };

  static defaultProps = {
    canEditApiName: false,
    canEditLabel: false,
    canPublish: false,
    canDelete: false,
  };

  state = {
    error: null,
    showDeleteModal: null,
    healthCheckProgress: HealthCheckProgress.Loading,
    healthCheckResults: {
      errors: [],
      warnings: [],
    },
  };

  onSave(data) {
    const {releaseHref, save} = this.props;
    const oldProps = pick(this.props, ['api_name', 'label', 'state']);

    return save(releaseHref, data, oldProps).catch(this.onError);
  }

  onError = (response) => {
    const message = flatten(Object.values(response.errors || {}))[0];
    this.setState({error: message});
  };

  onHealthCheckError = () => {
    this.setState({healthCheckProgress: HealthCheckProgress.Error});
  };

  onApiNameChange = (event) => {
    // This event fires even if the contents are the same. Avoid API call if so.
    const value = event.value;
    if (value === this.props.api_name) return;
    if (value.length > maxApiNameLength) {
      this.setState({error: [tRelease('api_name_length_error')]});
      return;
    }
    this.onSave({api_name: value}).then((response) => {
      // On Success, navigates to new releaseHref
      if (response) {
        navigate({url: response.releaseHref, replace: true});
      }
    });
  };

  onCancelDelete = () => {
    this.setState({showDeleteModal: false});
  };

  onShowDelete = () => {
    this.setState({showDeleteModal: true});
  };

  onLabelChange = (event) => {
    // This event fires even if the contents are the same. Avoid API call if so.
    const value = event.value;
    if (value === this.props.label) return;

    this.onSave({label: value});
  };

  onStatusChange = (event, data) => {
    // This event fires even if the selection was the same. Avoid API calls, if so.
    if (data.selection.length === 0) return;
    const value = data.selection[0].id;
    if (value === this.props.state) return;

    // Update the release via a Rails endpoint and look for errors:
    this.onSave({state: value});
  };

  onCloseToast = () => {
    this.setState({error: null});
  };

  packcheckIssues = () => {
    const items = this.props.items || [];
    const releaseHealthErrors = this.state.healthCheckResults.errors || [];
    const releaseHealthWarnings = this.state.healthCheckResults.warnings || [];

    return items.reduce((result, item) => {
      const newResult = { ...result };
      if (item.packcheck) {
        newResult.packCheckErrorCount += item.packcheck.errors.length;
        newResult.packCheckWarningCount += item.packcheck.warnings.length;
      }
      return newResult;
    }, {
      packCheckErrorCount: 0,
      packCheckWarningCount: 0,
      releaseHealthErrorCount: releaseHealthErrors.length,
      releaseHealthWarningCount: releaseHealthWarnings.length
    });
  };

  hasIssues = () => {
    const {
      packCheckErrorCount,
      packCheckWarningCount,
      releaseHealthErrorCount,
      releaseHealthWarningCount
    } = this.packcheckIssues();
    const totalErrors = packCheckErrorCount + releaseHealthErrorCount;
    const totalWarnings = packCheckWarningCount + releaseHealthWarningCount;
    return totalErrors + totalWarnings > 0;
  };

  renderReleaseIcon = () => (
    <div className="slds-m-right_large slds-show_small slds-text-align_center">
      {
        (this.props.items.length === 0 || this.hasIssues()) ?
          <Icon
            assistiveText={{label: 'check'}}
            category="utility"
            name="warning"
            colorVariant="error"
            size="large"
          /> :
          <Icon
            assistiveText={{label: 'check'}}
            category="action"
            name="check"
            size="large"
            containerClassName="tds-bg_success"
          />
      }
    </div>
  );

  renderFields() {
    const states = this.props.nextStates.filter((item) => !(['published', 'deleted'].includes(item.id)));
    const selection = [states.find((option) => option.id === this.props.state)];

    return (
      <div className="slds-col slds-size_6-of-12 slds-m-right_large slds-m-bottom_large">
        <div className="slds-grid slds-wrap">
          <div className="slds-grow">
            <div>{tRelease(`details.status.${this.props.state}`)}</div>
            <div data-test="release-label-inline-edit">
              <InlineEdit
                id={'release-label'}
                label={tRelease('label')}
                placeholder={tRelease('label')}
                disabled={!this.props.canEditLabel}
                onChange={this.onLabelChange}
                value={this.props.label}
                required
              />
            </div>
          </div>
        </div>
        <div className="slds-grid slds-m-top_x-small">
          <div className="slds-col slds-m-right--small" data-test="release-api-name-inline-edit">
            <InlineEdit
              id={'release-api-name'}
              label={tRelease('api_name')}
              placeholder={tRelease('api_name')}
              disabled={!this.props.canEditApiName}
              onChange={this.onApiNameChange}
              value={this.props.api_name}
              required
            />
          </div>
          <div className="slds-col" data-test="release-status-switcher">
            <Combobox
              id="release-state"
              events={{onSelect: this.onStatusChange}}
              labels={{
                label: tRelease('state.header'),
                placeholderReadOnly:
                  this.props.state.charAt(0).toUpperCase() + this.props.state.slice(1),
              }}
              options={states}
              disabled={isEmpty(states)}
              selection={selection}
              value={this.props.state}
              variant="readonly"
              multiple={false}
            />
          </div>
        </div>
      </div>
    );
  }

  renderDeleteModal() {
    const {showDeleteModal} = this.state;
    const {releaseHref, label} = this.props;

    return (
      <ReleaseDeleteModal
        isOpen={showDeleteModal}
        onCancel={this.onCancelDelete}
        href={releaseHref}
        label={label}
      />
    );
  }

  renderDeleteButton() {
    const disabled = !this.props.canDelete;
    return (
      <Button
        disabled={disabled}
        key="delete"
        label={tActions('delete')}
        title={disabled ? tRelease('delete_disabled') : null}
        type="submit"
        onClick={this.onShowDelete}
        variant="neutral"
      />
    );
  }

  renderPreviewButton() {
    const href = this.props.canPreview ? this.props.previewReleaseHref : undefined;
    return (
      <ButtonLink
        disabled={!this.props.canPreview}
        variant="neutral"
        label={tActions('preview')}
        href={href}
        target={'_blank'}
      />
    );
  }

  renderPublishButton() {
    const href = this.props.canPublish ? this.props.publishReleaseHref : undefined;
    return (
      <ButtonLink
        disabled={!this.props.canPublish}
        variant="neutral"
        label={tActions('publish')}
        href={href}
      />
    );
  }

  renderButtonGroup() {
    return (
      <div
        className="slds-col_bump-left slds-m-bottom_x-small slds-m-right_medium"
        data-test="release-actions"
      >
        <ButtonGroup>
          { this.renderDeleteButton() }
          { this.renderPreviewButton() }
          { this.renderPublishButton() }
        </ButtonGroup>
      </div>
    );
  }

  renderAddButton() {
    return this.props.canAddManifestItem ? (
      <div data-test="release-add-content-enabled">
        <ButtonLink
          label={tActions('add_content')}
          variant="brand"
          href={this.props.addItemsHref}
        />
      </div>
    ) : (
      <div data-test="release-add-content-disabled">
        <Button
          label={tActions('add_content')}
          variant="neutral"
          disabled
        />
      </div>
    );
  }

  renderReleaseHealthStatusBar = () => {
    let alertHeading;
    let alertLink;
    let alertLinkAction = () => {
      navigate({url: this.props.releaseIssuesHref, hard: true});
    };
    let alertIcon;
    let alertStyle;
    let alertVariant;
    const {
      packCheckErrorCount,
      packCheckWarningCount,
      releaseHealthErrorCount,
      releaseHealthWarningCount
    } = this.packcheckIssues();

    const totalWarnings = packCheckWarningCount + releaseHealthWarningCount;
    const totalErrors = packCheckErrorCount + releaseHealthErrorCount;
    const noItems = (!this.props.items || this.props.items.length === 0);

    if (this.props.state !== 'published' && this.state.healthCheckProgress === HealthCheckProgress.Loading) {
      alertVariant = 'info';
      alertIcon = 'spinner';
      alertHeading = tRelease('packcheck_checking_header');
      alertStyle = {'backgroundColor': '#16325c'};
    } else if (this.props.state !== 'published' && this.state.healthCheckProgress === HealthCheckProgress.Error) {
      alertVariant = 'error';
      alertIcon = 'ban';
      alertHeading = tRelease('packcheck_checking_failed_header');
      alertLink = tRelease('packcheck_checking_failed_link');
      alertLinkAction = () => {
        window.location.reload();
      };
    } else if (noItems && releaseHealthWarningCount === 1) {
      // Need to check releaseHealthWarningCount === 1 because
      // no items produce 1 release Health Warning.
      // If there are more (e.g. the rebase problem), we have to show them.
      alertVariant = 'error';
      alertHeading = tRelease('packcheck_no_items_header');
    } else if (totalWarnings + totalErrors === 0) {
      alertVariant = 'info';
      alertHeading = tRelease('packcheck_ok_header');
    } else if (totalErrors > 0 && totalWarnings > 0) {
      alertVariant = 'error';
      const labelPrefix =
          totalErrors === 1 ?
            'packcheck_error' :
            'packcheck_errors';
      const labelSuffix =
          totalWarnings === 1 ? '_and_warning_header' : '_and_warnings_header';
      alertHeading = tRelease(`${labelPrefix}${labelSuffix}`, {
        error_count: totalErrors,
        warning_count: totalWarnings
      });
      alertLink = tRelease('packcheck_errors_and_warnings_header_link');
    } else if (totalErrors > 0) {
      alertVariant = 'error';
      alertHeading = tRelease('packcheck_errors_header', {
        count: totalErrors
      });
      alertLink = tRelease('packcheck_errors_header_link');
    } else {
      alertVariant = 'error';
      const alertKey = `packcheck_warnings_header${this.props.state === 'published' ? '_published' : ''}`;
      alertHeading = tRelease(alertKey, { count: totalWarnings });
      alertLink = tRelease('packcheck_warnings_header_link');
    }

    if (packCheckErrorCount === 0 &&
        packCheckWarningCount === 0 &&
        releaseHealthErrorCount === 0 &&
        releaseHealthWarningCount === 0 &&
        !this.props.canPublish) {
      return null;
    }

    return (
      <div className="slds-grid slds-wrap">
        <Alert
          labels={{
            heading: alertHeading,
            headingLink: alertLink,
          }}
          onClickHeadingLink={alertLinkAction}
          icon={alertIcon}
          skipContainer
          style={alertStyle}
          variant={alertVariant}
        />
      </div>
    );
  };

  renderToast = () => {
    const {error} = this.state;

    return !!error && (
      <Toast
        labels={{heading: error}}
        variant="error"
        onRequestClose={this.onCloseToast}
      />
    );
  };

  renderPoller = () => this.props.state !== 'published' &&
    (
      <ReleaseHealthPoller
        jobId={this.props.releaseHealthCheckJobId}
        onComplete={(response) => this.setState({
          healthCheckProgress: HealthCheckProgress.Complete,
          healthCheckResults: response.data.results,
        })}
        onError={this.onHealthCheckError}
        onTimeout={this.onHealthCheckError} />
    )

  render() {
    return (
      <IconContext>
        <div>
          <div className="slds-p-around_large">
            <div className="slds-container_x-large slds-container_center">
              <div className="slds-grid slds-wrap">
                { this.renderPoller() }
                { this.renderReleaseIcon() }
                { this.renderFields() }
                { this.renderButtonGroup() }
                { this.renderAddButton() }
                { this.renderToast() }
                { this.renderDeleteModal() }
              </div>
            </div>
          </div>
          { this.renderReleaseHealthStatusBar() }
        </div>
      </IconContext>
    );
  }
}
